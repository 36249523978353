<template>

  <v-dialog
    width="700"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">
      <builder-in-progress-component :showBuilderInProgressModal="showBuilderInProgressModal"
                                     @closeModal="showBuilderInProgressModal = false"
                                     @clearRegion="clearData"/>

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Font for <span class="font-weight-black">{{ coverSheet.name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-tabs
          v-model="tab"
          centered
          icons-and-text
          class="custom-tab mb-6"
          color="secondary"
        >
          <v-tab key="0">
            <v-icon>mdi-list</v-icon>
            Font
          </v-tab>

          <v-tab key="1">
            <v-icon>mdi-list</v-icon>
            Margins
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="0">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-select
                      label="Font Family"
                      outlined
                      small
                      hide-details
                      v-model="fontFamily"
                      :items="fontFamilies"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      label="Font Size"
                      outlined
                      small
                      hide-details
                      v-model="fontSize"
                      :items="fontSizes"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="1">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card
                      elevation="3"
                      style="width: 2.83in; height: 3.66in;"
                    >
                      <v-card-text class="pa-0" style="padding: 0.166in !important; position: relative; display: block; width: 100%; height: 100%;">

                        <span
                          style="border: 1px dashed rgba(0,0,0,0.3); position: absolute;"
                          :style="updateMargins"
                        >
                            Content
                        </span>

                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col class="pl-12">
                    <v-row>
                      <v-col class="d-flex mb-4 text-uppercase font-weight-medium">
                        Letter Paper (8.5" x 11")
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex">
                        <v-text-field
                          outlined
                          label="Top"
                          hide-details
                          v-model="topMargin"
                          readonly
                          suffix="in"
                          dense
                          class="mr-6"
                        ></v-text-field>
                        <v-btn
                          depressed
                          icon
                          @click="decrementMargin('top')"
                          :disabled="topMargin<=marginMin"
                        >
                          <v-icon size="18">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          icon
                          @click="incrementMargin('top')"
                          :disabled="topMargin>=marginMax"
                        >
                          <v-icon size="18">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex">
                        <v-text-field
                          outlined
                          label="Right"
                          hide-details
                          readonly
                          v-model="rightMargin"
                          suffix="in"
                          dense
                          class="mr-6"
                        ></v-text-field>
                        <v-btn
                          depressed
                          icon
                          @click="decrementMargin('right')"
                          :disabled="rightMargin<=marginMin"
                        >
                          <v-icon size="18">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          icon
                          @click="incrementMargin('right')"
                          :disabled="rightMargin>=marginMax"
                        >
                          <v-icon size="18">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex">
                        <v-text-field
                          outlined
                          label="Bottom"
                          type="number"
                          hide-details
                          readonly
                          v-model="bottomMargin"
                          suffix="in"
                          dense
                          class="mr-6"
                        ></v-text-field>
                        <v-btn
                          depressed
                          icon
                          @click="decrementMargin('bottom')"
                          :disabled="bottomMargin<=marginMin"
                        >
                          <v-icon size="18">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          icon
                          @click="incrementMargin('bottom')"
                          :disabled="bottomMargin>=marginMax"
                        >
                          <v-icon size="18">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex">
                        <v-text-field
                          outlined
                          label="Left"
                          type="number"
                          hide-details
                          readonly
                          v-model="leftMargin"
                          suffix="in"
                          dense
                          class="mr-6"
                        ></v-text-field>
                        <v-btn
                          depressed
                          icon
                          @click="decrementMargin('left')"
                          :disabled="leftMargin<=marginMin"
                        >
                          <v-icon size="18">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          icon
                          @click="incrementMargin('left')"
                          :disabled="leftMargin>=marginMax"
                        >
                          <v-icon size="18">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="saveSettings">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import CoverSheetTemplateBuilder from '@/models/CoverSheetTemplateBuilder'
import axios from 'axios'
import BuilderInProgressComponent from '@/components/AgendaStyles/Regions/BuilderInProgressComponent'

function initialState () {
  return {
    draft: Boolean,
    version_date: '',
    rows: {},
    settings: {
      margins: {
        top: '',
        right: '',
        bottom: '',
        left: ''
      },
      font: {
        font_family: '',
        font_size: ''
      }
    },
    showBuilderInProgressModal: false,
    tab: 0,
    marginMax: 2.500,
    marginMin: 0.500,
    marginInterval: 0.25,
    margins: {
      top: 0.500,
      right: 0.500,
      bottom: 0.500,
      left: 0.500
    },
    fontFamilies: [
      'Algerian',
      'Arial',
      'Calibri',
      'Cambria',
      'Courier',
      'Franklin Gothic',
      'Futura',
      'Helvetica',
      'Rockwell',
      'Times New Roman',
      'Verdana'
    ],
    fontSizes: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
  }
}

export default Vue.extend({
  name: 'CoverSheetPageSettings',
  props: {
    coverSheet: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  components: {
    BuilderInProgressComponent
  },
  data: function () {
    return initialState()
  },
  computed: {
    ...mapState('coversheetbuilder', ['contentHasBeenModified']),
    contentHasBeenModified: {
      get () {
        return this.$store.state.coversheetbuilder.contentHasBeenModified
      },
      set (value) {
        this.$store.dispatch('coversheetbuilder/setContentHasBeenModified', value)
      }
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    fontFamily: {
      get () {
        return this.settings?.font?.font_family ?? ''
      },
      set (value) {
        if (!this.settings?.font) this.$set(this.settings, 'font', {})
        this.$set(this.settings.font, 'font_family', value)
      }
    },
    fontSize: {
      get () {
        return this.settings?.font?.font_size ?? ''
      },
      set (value) {
        if (!this.settings?.font) this.$set(this.settings, 'font', {})
        this.$set(this.settings.font, 'font_size', value)
      }
    },
    topMargin: {
      get () {
        return this.settings?.margins?.top ?? 0.50
      },
      set (value) {
        if (!this.settings?.margins) this.$set(this.settings, 'margins', {})
        this.$set(this.settings.margins, 'top', parseFloat(value))
        this.$store.dispatch('coversheetbuilder/setContentHasBeenModified')
      }
    },
    leftMargin: {
      get () {
        return this.settings?.margins?.left ?? 0.50
      },
      set (value) {
        if (!this.settings?.margins) this.$set(this.settings, 'margins', {})
        this.$set(this.settings.margins, 'left', parseFloat(value))
        this.$store.dispatch('coversheetbuilder/setContentHasBeenModified')
      }
    },
    rightMargin: {
      get () {
        return this.settings?.margins?.right ?? 0.50
      },
      set (value) {
        if (!this.settings?.margins) this.$set(this.settings, 'margins', {})
        this.$set(this.settings.margins, 'right', parseFloat(value))
        this.$store.dispatch('coversheetbuilder/setContentHasBeenModified')
      }
    },
    bottomMargin: {
      get () {
        return this.settings?.margins?.bottom ?? 0.50
      },
      set (value) {
        if (!this.settings?.margins) this.$set(this.settings, 'margins', {})
        this.$set(this.settings.margins, 'bottom', parseFloat(value))
        this.$store.dispatch('coversheetbuilder/setContentHasBeenModified')
      }
    },
    updateMargins () {
      return {
        width: 'calc(100% - ' + (parseFloat(this.leftMargin) + parseFloat(this.rightMargin)) / 3 + 'in)',
        height: 'calc(100% - ' + (parseFloat(this.topMargin) + parseFloat(this.bottomMargin)) / 3 + 'in)',
        top: this.topMargin / 3 + 'in',
        left: this.leftMargin / 3 + 'in'
      }
    }
  },
  methods: {
    incrementMargin (item) {
      this[item + 'Margin'] = parseFloat(this[item + 'Margin'] + this.marginInterval).toFixed(3)
    },
    decrementMargin (item) {
      this[item + 'Margin'] = parseFloat(this[item + 'Margin'] - this.marginInterval).toFixed(3)
    },
    clearData () {
      this.showBuilderInProgressModal = false
      Object.assign(this.$data, initialState())
      this.show = false
      this.$store.dispatch('coversheetbuilder/RESET_MODULE')
    },
    closeModal () {
      if (this.contentHasBeenModified) {
        this.showBuilderInProgressModal = true
      } else {
        this.clearData()
      }
    },
    getTemplate () {
      // if (this.settings.length > 0) return false
      // TODO: Change temporary URL // this.coverSheet.id
      // const id = 'CS_WE6PRnK85F_Xw35'
      CoverSheetTemplateBuilder.find(this.coverSheet.id).then(response => {
        let template = {}
        if (this.coverSheet.draft) {
          template = response.draftTemplate
        } else {
          template = response.activeTemplate
        }
        const { draft, version_date, settings, rows } = template
        this.draft = draft
        this.settings = settings ?? {}
        this.rows = rows ?? {}
        this.version_date = version_date
      }).catch(error => {
        console.error('CoverSheetTemplateBuilder PageSettings Error:', error.message)
      })
    },
    saveSettings () {
      const data = {
        version_date: this.version_date,
        rows: this.rows,
        settings: this.settings,
        draft: this.draft
      }
      // // TODO: Change temporary URL // this.coverSheet.id
      // const id = 'CS_WE6PRnK85F_Xw35'
      const axiosUrl = '/api/cover-sheet-template-builder/' + this.coverSheet.id
      axios({
        method: 'put',
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data
      }).then(response => {
        this.contentHasBeenModified = false
      }).catch(error => {
        console.error(error.message)
      })
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.getTemplate()
      }
    }
  }
})
</script>
