<template>

  <v-dialog
    width="1100"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-tabs
        v-model="tab"
        color="transparent"
        grow class="d-none"
      >
      </v-tabs>

      <v-tabs-items v-model="tab" class="transparent">

        <v-tab-item key="0">

          <v-card-title class="mb-12" style="height: 70px;">

            <v-row>
              <v-col>
                <span>Rows of <span class="font-weight-black">{{ coverSheet.name }}</span></span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="darkGrey"
                      size="18"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    Explanation for this area
                  </span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex justify-end align-center">
                <v-switch
                  inset
                  label="Preview"
                  color="secondary"
                  v-model="previewRows"
                  class="mt-0"
                  hide-details
                ></v-switch>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      height="40"
                      color="orange lighten-2"
                      class="white--text ml-8"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">mdi-plus</v-icon>
                      Add Row
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="addRowDuplicate">
                      <v-list-item-title>Duplicate last row</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="addRow">
                      <v-list-item-title>Empty</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

          </v-card-title>

          <v-card-text class="pa-3 mb-6 overflow-auto">

            <div class="letter-wrapper">
              <v-card
                elevation="8"
                class="d-flex flex-column rounded-0 letter-paper"
                style="padding: 0.5in"
              >
                <!--                <cover-sheet-row v-for="(row, index) in rows"-->
                <!--                                 :key="index + Math.random()"-->
                <!--                                 @goToRow="goToRow"-->
                <!--                                 :rowIndex="index"-->
                <!--                                 :row="row"/>-->
                  <span v-for="(row, rowIndex) in rows" :key="rowIndex + Math.random()">
                   <span class="d-flex row-editor">
                       <div class="d-flex" style="position: relative">
                          <cover-sheet-column v-for="(column, columnIdx) in row.columns"
                                              :key="columnIdx + Math.random()"
                                              :column="column"
                          />
                          <span class="edit-column">
                  <v-menu
                    offset-x
                    open-on-hover
                    min-width="30"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="transparent"
                        tile
                        block
                        @click="goToRow(row, rowIndex)"
                      >
                        <v-icon>mdi-vertical-dots</v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      dense
                    >
                      <v-list-item class="d-flex justify-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="insertRowBelow(rowIndex)"
                            >
                              <v-icon color="secondary">mdi-table-row-plus-before</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Insert row before
                          </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="goToRow(row, rowIndex)"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Edit row
                          </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="moveRow(rowIndex, 'up')"
                            >
                              <v-icon>mdi-chevron-up</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Move row up
                          </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="moveRow(rowIndex, 'down')"
                            >
                              <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Move row down
                          </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @mouseenter="addRegionMenu=false"
                            >
                              <v-icon>mdi-format-page-break</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Insert Page Break
                          </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="removeRow(rowIndex)"
                            >
                              <v-icon color="important">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Delete row
                          </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="insertRowAfter(rowIndex)"
                            >
                              <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Insert row after
                          </span>
                        </v-tooltip>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
                       </div>
                   </span>

                  </span>
              </v-card>
            </div>

          </v-card-text>

          <v-card-actions class="d-flex align-center justify-center">
            <v-spacer></v-spacer>
            <v-btn depressed width="130" @click="goToRows">Cancel</v-btn>
            <v-btn depressed width="130" color="secondary" @click="updateTemplate">Update</v-btn>
          </v-card-actions>

        </v-tab-item>

        <v-tab-item key="1">

          <v-card-title class="mb-0" style="height: 70px;">

            <v-row>
              <v-col>
                <v-btn
                  depressed
                  icon
                  @click="goToRows"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span>Row</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="darkGrey"
                      size="18"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    Explanation for this area
                  </span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex justify-end align-center">
                <v-switch
                  inset
                  label="Preview"
                  color="secondary"
                  v-model="previewRow"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>

          </v-card-title>

          <v-card-text class="pa-3 mb-6 overflow-auto">

          <cover-sheet-row :key="selectedRowIndex + Math.random()"
                           @goToRow="goToRow"
                           @goToRows="goToRows"
                           :tab="tab"
                           :selectedRowIndex="selectedRowIndex" />
<!--                           :row="selectedRow"/>-->
          </v-card-text>

        </v-tab-item>

      </v-tabs-items>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import 'vue-slider-component/theme/default.css'
import CoverSheetRow from '@/components/ItemTypes/CoverSheetBuilder/CoverSheetRow'
import CoverSheetColumn from '@/components/ItemTypes/CoverSheetBuilder/CoverSheetColumn'
import CoverSheetTemplateBuilder from '@/models/CoverSheetTemplateBuilder'
import { mapState } from 'vuex'
import { moveRow } from '@/helpers/templateBuilderHelper'
import axios from 'axios'

export default Vue.extend({
  name: 'CoverSheetRows',
  components: {
    CoverSheetRow,
    CoverSheetColumn
  },
  props: {
    coverSheet: {
      required: true
    },
    item_type_id: {
      required: true
    },
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    draft: false,
    version_date: '',
    showBuilderInProgressModal: false,
    selectedRowIndex: Number,
    selectedRow: {},
    tab: 0,
    tabRegion: 0,
    rowColumns: 0,
    rowContent: [
      '',
      '',
      '',
      ''],
    columnDots: [0, 1.5, 1.75, 4.5, 7.5],
    sliderOptions: {
      process: false,
      tooltipPlacement: 'bottom',
      adsorb: true,
      clickable: false,
      interval: 0.250,
      max: 7.5,
      min: 0,
      minRange: 0.250
    },
    rowSample: [
      { text: 'Name' },
      { text: 'Administrator' },
      { text: 'Email' },
      { text: 'Workgroups' },
      { text: 'Actions' }
    ]
  }),
  computed: {
    ...mapState('coversheetbuilder', ['previewRows', 'previewRow']),
    previewRow: {
      get () {
        return this.$store.state.coversheetbuilder.previewRow
      },
      set (value) {
        this.$store.dispatch('coversheetbuilder/setPreviewRow', value)
      }
    },
    previewRows: {
      get () {
        return this.$store.state.coversheetbuilder.previewRows
      },
      set (value) {
        this.$store.dispatch('coversheetbuilder/setPreviewRows', value)
      }
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    rows: {
      get () {
        return this.$store.state.coversheetbuilder.rows
      },
      set (value) {
        this.$store.dispatch('coversheetbuilder/setRows', value)
      }
    }
  },
  methods: {
    goToRows () {
      this.tab = 0
    },
    goToRow (row, index) {
      this.selectedRow = row
      this.selectedRowIndex = index
      this.$store.dispatch('coversheetbuilder/setSelectedRow', row)
      this.tab = 1
    },
    getRows () {
      if (this.rows.length > 0) return false
      CoverSheetTemplateBuilder.find(this.coverSheet.id).then(response => {
        console.log('res', response)
        let template = {}
        if (this.coverSheet.draft) {
          template = response.draftTemplate
        } else {
          template = response.activeTemplate
        }
        const {
          rows,
          draft,
          version_date
        } = template

        console.log('template', template)
        this.$store.dispatch('coversheetbuilder/setRows', rows)
        this.draft = draft
        this.version_date = version_date
        // this.settings = settings
        this.goToAllRows()
      }).catch(error => {
        console.log('coversheetbuilder errror', error.message)
      })
    },
    goToAllRows () {
      this.tab = 0
    },
    selectColumn (key) {
      this.rowColumns = key
    },
    insertRowBelow (value) {
      this.insertRowAtIndex(value - 1)
    },
    insertRowAfter (value) {
      this.insertRowAtIndex(value)
    },
    removeRow (index) {
      Vue.delete(this.rows, parseInt(index))
      const newRowsObj = {}
      const rowVals = Object.values(this.rows)

      rowVals.forEach((elm, idx) => {
        newRowsObj[idx + 1] = elm
      })
      this.rows = newRowsObj
      this.$store.dispatch('coversheetbuilder/setContentHasBeenModified')
    },
    moveRow (index, direction) {
      const originalIndex = index - 1
      const rowVals = Object.values(this.rows)
      const newIndex = direction === 'up' ? originalIndex - 1 : originalIndex + 1
      const newRowsArr = this.move(rowVals, originalIndex, newIndex)

      const newRowsObj = {}
      newRowsArr.forEach((elm, idx) => {
        newRowsObj[idx + 1] = elm
      })

      this.rows = newRowsObj
      this.$store.dispatch('coversheetbuilder/setContentHasBeenModified')
    },
    move (array, from, to) {
      if (to === from) return array
      if (array.length - 1 < to) return array
      if (to < 0) return array

      const target = array[from]
      const increment = to < from ? -1 : 1

      for (let k = from; k !== to; k += increment) {
        array[k] = array[k + increment]
      }
      array[to] = target
      return array
    },
    async insertRowAtIndex (index) {
      const emptyRow = await this.getEmptyRow()
      const rowVals = Object.values(this.rows)
      rowVals.splice(index, 0, emptyRow)

      const newRowsObj = {}
      rowVals.forEach((elm, idx) => {
        newRowsObj[idx + 1] = elm
      })
      this.rows = newRowsObj
      await this.$store.dispatch('coversheetbuilder/setContentHasBeenModified')
    },
    async addRow () {
      const emptyRow = await this.getEmptyRow()
      if (Object.keys(this.rows).length === 0) this.rows = {}
      this.$set(this.rows, Object.keys(this.rows).length + 1, emptyRow)
      await this.$store.dispatch('coversheetbuilder/setContentHasBeenModified')
    },
    addRowDuplicate () {
      const rows = this.rows
      if (rows === undefined || rows.length === 0) {
        return false
      }
      const lastRowKey = Object.keys(rows).pop()
      const lastRow = JSON.parse(JSON.stringify(this.rows[lastRowKey]))
      this.$set(this.rows, Object.keys(rows).length + 1, lastRow)
      this.$store.dispatch('coversheetbuilder/setContentHasBeenModified')
    },
    async getEmptyRow () {
      const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/cover-sheet-template-builder/empty-row')
      return response.data
    },
    updateTemplate () {
      const data = {
        rows: this.rows,
        version_date: this.version_date,
        draft: this.draft
      }
      console.log('data', data)

      // TODO: Change temporary URL // this.coverSheet.id
      // const id = 'CS_WE6PRnK85F_Xw35'
      const axiosUrl = '/api/cover-sheet-template-builder/' + this.coverSheet.id
      axios({
        method: 'put',
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data
      }).then(response => {
        console.log('response', response)
        this.$store.dispatch('coversheetbuilder/setContentHasBeenModified', false)
      }).catch(error => {
        console.error(error.message)
      })
    }
  },
  watch: {
    show: function (value) {
      console.log('showing', value)
      if (value && this.rows.length === 0) {
        this.getRows()
      }
    }
  }
})
</script>
