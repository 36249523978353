<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6">
        <span>Publish cover sheet draft</span>
        <span><span class="font-weight-black">{{ coverSheet.name }}</span>?</span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-actions class="d-flex align-center justify-center">
        <v-btn depressed width="130" @click="show=false" class="mr-2">No</v-btn>
        <v-btn depressed width="130" class="ml-2" color="secondary" @click="publishDraft">Yes</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'CoverSheetDraftPublish',
  props: {
    coverSheet: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    form: {
      status: 'active'
    },
    saving: false
  }),
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    publishDraft () {
      this.saving = true
      this.form.user_id = this.user.id
      this.form.user_name = this.user.first_name + ' ' + this.user.last_name
      // TODO: FIX Cover Sheet ID // this.coverSheet.id
      // const id = 'CS_WE6PRnK85F_Xw35'
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/cover-sheet-template-builder/publish-draft/' + this.coverSheet.id, this.form)
        .then(() => {
          this.saving = false
          this.show = false
          this.$emit('reload', true)
        })
    }
  }
})
</script>
