<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-btn
            class="mr-3 secondary--text pa-0 pr-3"
            text
            @click="$router.push({ name: 'ItemTypes' })"
          >
            <v-icon size="30">mdi-chevron-left</v-icon>
            Item Types
          </v-btn>
          <h2 class="darkGrey--text">
            Cover Sheets from <span v-if="itemType !== undefined">{{ itemType.type }}</span>
          </h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Board Members
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Cover Sheet
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex mb-6">
      <v-col cols="6" offset="6">
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="coverSheets"
            :options.sync="options"
            :loading="loading"
            :search="search"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
          >
            <template v-slot:item.sortable>
              <v-icon color="lightGrey">
                mdi-drag-horizontal-variant
              </v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              <span class="py-6 d-flex flex-column">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
                <span class="text-caption">
                  {{ item.description }}
                </span>
              </span>
            </template>
            <template v-slot:item.default="{ item }">
              <span v-if="item.default===1">
                <span>
                  <v-chip>
                    Default
                  </v-chip>
                </span>
              </span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-tooltip top v-if="item.pending">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-timer-sand
                  </v-icon>
                </template>
                <span>
                  Pending
                </span>
              </v-tooltip>
              <v-tooltip top v-if="item.active">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="positive"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-check-circle-outline
                  </v-icon>
                </template>
                <span>
                    Active
                  </span>
              </v-tooltip>
              <v-tooltip top v-if="item.paused">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="important"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pause
                  </v-icon>
                </template>
                <span>
                  Paused
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-row align-center justify-end">
                <v-menu offset-y transition="slide-x-transition" v-if="item.draft">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      small
                    >
                      Draft
                      <v-icon size="14">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsDraft"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

                <v-tooltip top v-if="!item.draft">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="openModal('rows', null, item)"
                    >
                      <v-icon size="20">
                        mdi-table-edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Rows
                  </span>
                </v-tooltip>

                <v-tooltip top v-if="!item.draft">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="openModal('pageSettings', null, item)"
                    >
                      <v-icon size="20">
                        mdi-file-cog-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Page settings
                  </span>
                </v-tooltip>

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenuFiltered(item)"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <cover-sheet-create-update @reload="getCoverSheets" v-model="modals.createUpdate" :modeType="modeType" :coverSheet="coverSheet" :item_type_id="paramTypeId"></cover-sheet-create-update>
    <cover-sheet-edit @reload="getCoverSheets" v-model="modals.edit" :coverSheet="coverSheet" :item_type_id="paramTypeId"></cover-sheet-edit>
    <cover-sheet-rows v-model="modals.rows" :coverSheet="coverSheet" :item_type_id="paramTypeId"></cover-sheet-rows>
    <cover-sheet-page-settings v-model="modals.pageSettings" :coverSheet="coverSheet" :item_type_id="paramTypeId"></cover-sheet-page-settings>
    <cover-sheet-previous-versions v-model="modals.previous" :coverSheet="coverSheet"></cover-sheet-previous-versions>
    <cover-sheet-default @reload="getCoverSheets" v-model="modals.default" :coverSheet="coverSheet" :item_type_id="paramTypeId"></cover-sheet-default>
    <cover-sheet-resume @reload="getCoverSheets" v-model="modals.resume" :coverSheet="coverSheet" :item_type_id="paramTypeId"></cover-sheet-resume>
    <cover-sheet-pause @reload="getCoverSheets" v-model="modals.pause" :coverSheet="coverSheet" :item_type_id="paramTypeId"></cover-sheet-pause>
    <cover-sheet-activate @reload="getCoverSheets" v-model="modals.activate" :coverSheet="coverSheet" :item_type_id="paramTypeId"></cover-sheet-activate>
    <cover-sheet-delete @reload="getCoverSheets" v-model="modals.delete" :coverSheet="coverSheet" :item_type_id="paramTypeId"></cover-sheet-delete>
    <cover-sheet-draft-publish @reload="getCoverSheets" :coverSheet="coverSheet" v-model="modals.draftPublish"></cover-sheet-draft-publish>
    <cover-sheet-draft-delete @reload="getCoverSheets" :coverSheet="coverSheet" v-model="modals.draftDelete"></cover-sheet-draft-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import CoverSheetCreateUpdate from '@/components/ItemTypes/CoverSheetCreateUpdate'
import CoverSheetEdit from '@/components/ItemTypes/CoverSheetEdit'
import CoverSheetRows from '@/components/ItemTypes/CoverSheetBuilder/CoverSheetRows'
import CoverSheetPageSettings from '@/components/ItemTypes/CoverSheetPageSettings'
import CoverSheetPreviousVersions from '@/components/ItemTypes/CoverSheetPreviousVersions'
import CoverSheetDefault from '@/components/ItemTypes/CoverSheetDefault'
import CoverSheetResume from '@/components/ItemTypes/CoverSheetResume'
import CoverSheetPause from '@/components/ItemTypes/CoverSheetPause'
import CoverSheetActivate from '@/components/ItemTypes/CoverSheetActivate'
import CoverSheetDelete from '@/components/ItemTypes/CoverSheetDelete'
import CoverSheetDraftPublish from '@/components/ItemTypes/CoverSheetDraftPublish'
import CoverSheetDraftDelete from '@/components/ItemTypes/CoverSheetDraftDelete'
import CoverSheet from '@/models/CoverSheet'
import ItemType from '@/models/ItemType'
import axios from 'axios'

function initialState () {
  return {
    itemType: {},
    options: {},
    search: '',
    loading: false,
    coverSheet: {},
    coverSheets: [{
      id: '',
      name: '',
      description: '',
      active: false,
      pending: false,
      paused: false,
      draft: false,
      activeTemplate: {},
      draftTemplate: {}
    }],
    paramTypeId: '',
    modeType: '',
    modals: {
      createUpdate: false,
      edit: false,
      rows: false,
      pageSettings: false,
      previous: false,
      duplicate: false,
      default: false,
      resume: false,
      pause: false,
      activate: false,
      delete: false,
      draftPublish: false,
      draftDelete: false
    },
    breadcrumbsItems: [
      {
        text: 'Admin Panel',
        disabled: false,
        href: ''
      },
      {
        text: 'Item Types',
        disabled: false,
        href: ''
      },
      {
        text: 'Cover Sheets from Item Type Name',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      { icon: 'mdi-pencil', text: 'Edit', actions: 'createUpdate', optional: 'edit' },
      { icon: 'mdi-history', text: 'Previous Versions', actions: 'previous' },
      { icon: 'mdi-content-copy', text: 'Duplicate', actions: 'createUpdate', optional: 'duplicate' },
      { icon: 'mdi-star', text: 'Set as Default', actions: 'default' },
      { icon: 'mdi-check-circle-outline', text: 'Activate', actions: 'activate' },
      { icon: 'mdi-play', text: 'Resume', actions: 'resume' },
      { icon: 'mdi-pause', text: 'Pause', type: 'important', actions: 'pause' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    itemsDraft: [
      { icon: 'mdi-table-edit', text: 'Rows', actions: 'rows' },
      { icon: 'mdi-file-cog-outline', text: 'Page Settings', actions: 'pageSettings' },
      { icon: 'mdi-check-circle-outline', text: 'Publish', actions: 'draftPublish' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'draftDelete' }
    ],
    headers: [
      { text: '', value: 'sortable' },
      { text: 'Name', value: 'name' },
      { text: '', value: 'default', sortable: false, align: 'center' },
      { text: 'Status', value: 'status', sortable: false, align: 'center' },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    coverSample: [
      { name: 'Cover Sheet 1', description: 'Nulla eu convallis felis. Phasellus pharetra.', default: true, active: true, pending: false, paused: false, draft: true },
      { name: 'Cover Sheet 2', description: 'Mauris ut ipsum sed felis dapibus auctor vitae ut velit.', default: false, active: false, pending: false, paused: true, draft: false },
      { name: 'Cover Sheet 3', description: 'Etiam fermentum libero metus. Mauris at consequat velit, at pretium ipsum.', default: false, active: false, pending: true, paused: false, draft: false }
    ]
  }
}

export default Vue.extend({
  name: 'ItemTypeCoverSheets',
  props: {
    itemTypeProp: {},
    item: {}
  },
  components: {
    Portal,
    CoverSheetCreateUpdate,
    CoverSheetEdit,
    CoverSheetRows,
    CoverSheetPageSettings,
    CoverSheetPreviousVersions,
    CoverSheetDefault,
    CoverSheetResume,
    CoverSheetPause,
    CoverSheetActivate,
    CoverSheetDelete,
    CoverSheetDraftPublish,
    CoverSheetDraftDelete
  },
  data: function () {
    return initialState()
  },
  methods: {
    openModal (item, optional, coverSheet) {
      if (optional) this.modeType = optional
      this.modals[item] = true
      if (coverSheet) this.coverSheet = coverSheet
    },
    async getItemTypeFromDatabase () {
      const itm = await ItemType
        .where('id', this.paramTypeId)
        .first()
      this.itemType = itm
    },
    async getCoverSheets () {
      this.loading = true
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage
      } = this.options

      let orderBy = null
      if (sortBy !== undefined && sortBy[0] !== undefined) {
        const sortDir = sortDesc[0] ? '-' : ''
        orderBy = sortDir + sortBy[0]
      }

      const coverSheets = await CoverSheet
        .page(page)
        .where('item_type_id', this.paramTypeId)
        .orderBy(orderBy)
        .get()
      this.loading = false
      this.coverSheets = coverSheets.data
      for (const elm of this.coverSheets) {
        elm.active = false
        elm.pending = false
        elm.paused = false
        elm[elm.status] = true

        const templatesRes = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/cover-sheet-template-builder/active-and-draft-template/' + elm.id)
        const { activeTemplate, draftTemplate } = templatesRes.data
        elm.activeTemplate = activeTemplate
        elm.draftTemplate = draftTemplate
        if (Object.keys(draftTemplate).length) {
          elm.draft = true
        }
        if (elm.status === 'inactive') {
          elm.paused = true
        }
      }
      this.$forceUpdate()
    },
    itemsMenuFiltered (item) {
      const itemsToRemove = []

      if (item.status === 'pending') {
        itemsToRemove.push('resume', 'pause')
      }

      if (item.status === 'active') {
        itemsToRemove.push('resume', 'activate')
      }

      if (item.status === 'inactive') {
        itemsToRemove.push('pause', 'activate')
      }

      if (item.default === 1) {
        itemsToRemove.push('default')
      }

      return this.itemsMenu.filter(function (el) { return !itemsToRemove.includes(el.actions) })
    }
  },
  async mounted () {
    this.itemType = this.itemTypeProp
    this.paramTypeId = this.$route.params.typeid

    if (this.itemType === undefined) {
      await this.getItemTypeFromDatabase()
    }
    await this.getCoverSheets()
    // Update breadcrumb
    this.breadcrumbsItems[2].text = 'Cover Sheets from ' + this.itemType.type
  }
})

/*

The first Cover Sheet included will always be set as default.
And in this scenario we won't the "Set as Default" option in the actions menu.
If we add a second Cover Sheet, this option will become available.

*/

</script>
