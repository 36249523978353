<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>
          <span v-if="modeType=='create'">New Cover Sheet</span>
          <span v-if="modeType=='edit'">Edit Cover Sheet</span>
          <span v-if="modeType=='duplicate'">Duplicate Cover Sheet</span>
        </span>
        <span v-if="modeType=='edit'||modeType=='duplicate'"><span class="font-weight-black">{{ this.coverSheet.name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text class="text-center">

        <v-row>

          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  :label="modeType==='duplicate'?'New Name':'Name'"
                  v-model="aName"
                  :rules="rules.nameRules"
                  :counter="50"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  :label="modeType==='duplicate'?'New Description':'Description'"
                  v-model="aDescription"
                  :rules="rules.descRules"
                  :counter="512"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>

        </v-row>

        <!--
        The first Cover Sheet being included will always be set as default.
        So this option (below) won't be available in this case.
        -->
        <v-row v-if="modeType=='create'" class="mb-8 mt-6">
          <v-col>
            <v-switch
              inset
              label="Set as default"
              color="secondary"
              class="mt-0 pa-0 mr-4"
              v-model="aDefault"
            ></v-switch>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary">
          <span v-if="modeType=='create'" @click="submit">Next</span>
          <span v-if="modeType=='edit'" @click="submit">Update</span>
          <span v-if="modeType=='duplicate'" @click="submit">Duplicate</span>
        </v-btn>
      </v-card-actions>

    </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'CoverSheetCreateUpdate',
  props: {
    item_type_id: {
      required: true
    },
    coverSheet: {},
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    }
  },
  data: () => ({
    valid: true,
    saving: false,
    form: {
      id: '',
      name: '',
      description: '',
      item_type_id: '',
      default: false
    },
    rules: {
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters'
      ],
      descRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 512) || 'Description must be less than 512 characters'
      ]
    }
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    aName: {
      get () {
        return this.coverSheet.name
      },
      set (val) {
        this.form.name = val
      }
    },
    aDescription: {
      get () {
        return this.coverSheet.description
      },
      set (val) {
        this.form.description = val
      }
    },
    aDefault: {
      get () {
        return this.coverSheet.default
      },
      set (val) {
        this.form.default = val
      }
    }
  },
  methods: {
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.form = {
        status: 'active',
        default: false
      }
      this.saving = false
    },
    submit () {
      const formPassedValidation = this.$refs.form.validate()
      if (formPassedValidation === false) {
        return false
      }
      this.saving = true
      if (this.item_type_id) {
        this.form.item_type_id = this.item_type_id
      }

      const axiosAction = (this.modeType === 'create' || this.modeType === 'duplicate') ? 'post' : 'put'
      const axiosUrl = (this.modeType === 'edit') ? '/api/cover-sheets/' + this.coverSheet.id : '/api/cover-sheets'

      if (this.form.default == null) {
        this.form.default = true
      }

      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: this.form
      }).then(() => {
        this.resetModal()
        this.show = false
        this.$emit('reload', true)
      }).catch(error => {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      })
    }
  },
  watch: {
    show: function (value) {
      if (value === true && this.modeType === 'edit') {
        this.form.name = this.coverSheet.name
        this.form.description = this.coverSheet.description
      } else if (value === true && this.modeType === 'create') {
        this.resetModal()
      }
    }
  }
})
</script>
