<template>
    <div class="edit-cell d-flex align-center"
           :class="{ 'table-preview' : previewRows }"
           :style="{width: column.width + 'in'}"
      >
        {{ displayContent(column) }}
  </div>
</template>

<script>
import Vue from 'vue'
import 'vue-slider-component/theme/default.css'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'CoverSheetColumn',
  props: {
    column: {}
  },
  computed: {
    ...mapState('coversheetbuilder', ['previewRows'])
  },
  methods: {
    displayContent (column) {
      if (column.parsedContent !== undefined && column.parsedContent.length > 0) return column.parsedContent
      return column.content
    }
  }
})
</script>
