var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',{staticClass:"mb-6",staticStyle:{"min-height":"450px"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-tabs',{attrs:{"centered":"","color":"secondary"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.rowContent),function(rowKey,rowValue){return _c('v-tab',{key:rowValue},[_vm._v(_vm._s(rowKey.key))])}),1)],1)],1),_c('v-row',{staticClass:"d-flex mb-8"},[_c('v-col',{staticClass:"veryLightGrey pa-8"},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-textarea',{staticClass:"white mt-0 pt-0 rounded-0",attrs:{"height":"80","hide-details":"","outlined":"","no-resize":""},model:{value:(_vm.textAreaContent),callback:function ($$v) {_vm.textAreaContent=$$v},expression:"textAreaContent"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"letter-wrapper"},[_c('v-card',{staticClass:"d-flex flex-column rounded-0 letter-width",staticStyle:{"padding":"0.5in"},attrs:{"elevation":"8"}},[_c('span',{staticClass:"d-flex row-editor mb-6"},[_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},_vm._l((_vm.selectedRow.columns),function(item,key){return _c('div',{key:'col_' + key,ref:"rowCol",refInFor:true,staticClass:"edit-cell d-flex align-center",class:{ 'table-preview' : _vm.previewRow },style:({'width': item.width + 'in'}),on:{"click":function($event){return _vm.selectColumn(key)}}},[_vm._v(" "+_vm._s(_vm.displayContent(item))+" "),_c('span',{staticClass:"edit-column",style:(parseInt(_vm.selectedTab) + 1 === parseInt(key) ? {opacity:1} : {})},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","tile":"","block":""},on:{"click":function($event){return _vm.selectColumn(key)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vertical-dots")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.insertColumnBefore(key)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-column-plus-before")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert column before ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.selectColumn(key)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit column ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteColumn(key)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"important"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(" Delete column ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.insertColumnAfter(key)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-column-plus-after")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert column after ")])])],1)],1)],1)],1)])}),0)]),(!_vm.previewRow)?_c('vue-slider',_vm._b({staticClass:"custom-slider",attrs:{"min":_vm.sliderOptions.min,"max":_vm.sliderOptions.max,"interval":_vm.sliderOptions.interval,"enable-cross":false},on:{"change":_vm.updateRowColumns},model:{value:(_vm.columnDots),callback:function ($$v) {_vm.columnDots=$$v},expression:"columnDots"}},'vue-slider',_vm.sliderOptions,false)):_vm._e()],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }