var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1100","persistent":this.$store.state.modalNotCloseOutside,"no-click-animation":this.$store.state.modalWithoutAnimation},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-8 veryDarkGrey--text",attrs:{"elevation":"0","rounded":"0"}},[_c('span',{staticClass:"modal-close-btn"},[_c('v-btn',{attrs:{"icon":"","color":"grey"},on:{"click":function($event){_vm.show=false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-tabs',{staticClass:"d-none",attrs:{"color":"transparent","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"0"},[_c('v-card-title',{staticClass:"mb-12",staticStyle:{"height":"70px"}},[_c('v-row',[_c('v-col',[_c('span',[_vm._v("Rows of "),_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm.coverSheet.name))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"darkGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" Explanation for this area ")])])],1),_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","label":"Preview","color":"secondary","hide-details":""},model:{value:(_vm.previewRows),callback:function ($$v) {_vm.previewRows=$$v},expression:"previewRows"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text ml-8",attrs:{"depressed":"","height":"40","color":"orange lighten-2"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-plus")]),_vm._v(" Add Row ")],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":_vm.addRowDuplicate}},[_c('v-list-item-title',[_vm._v("Duplicate last row")])],1),_c('v-list-item',{on:{"click":_vm.addRow}},[_c('v-list-item-title',[_vm._v("Empty")])],1)],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"pa-3 mb-6 overflow-auto"},[_c('div',{staticClass:"letter-wrapper"},[_c('v-card',{staticClass:"d-flex flex-column rounded-0 letter-paper",staticStyle:{"padding":"0.5in"},attrs:{"elevation":"8"}},_vm._l((_vm.rows),function(row,rowIndex){return _c('span',{key:rowIndex + Math.random()},[_c('span',{staticClass:"d-flex row-editor"},[_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_vm._l((row.columns),function(column,columnIdx){return _c('cover-sheet-column',{key:columnIdx + Math.random(),attrs:{"column":column}})}),_c('span',{staticClass:"edit-column"},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","min-width":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","tile":"","block":""},on:{"click":function($event){return _vm.goToRow(row, rowIndex)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vertical-dots")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.insertRowBelow(rowIndex)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-row-plus-before")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert row before ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToRow(row, rowIndex)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit row ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRow(rowIndex, 'up')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move row up ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRow(rowIndex, 'down')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move row down ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-page-break")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert Page Break ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.removeRow(rowIndex)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"important"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(" Delete row ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.insertRowAfter(rowIndex)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-row-plus-after")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert row after ")])])],1)],1)],1)],1)],2)])])}),0)],1)]),_c('v-card-actions',{staticClass:"d-flex align-center justify-center"},[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","width":"130"},on:{"click":_vm.goToRows}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"depressed":"","width":"130","color":"secondary"},on:{"click":_vm.updateTemplate}},[_vm._v("Update")])],1)],1),_c('v-tab-item',{key:"1"},[_c('v-card-title',{staticClass:"mb-0",staticStyle:{"height":"70px"}},[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":_vm.goToRows}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('span',[_vm._v("Row")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"darkGrey","size":"18"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" Explanation for this area ")])])],1),_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('v-switch',{staticClass:"mt-0",attrs:{"inset":"","label":"Preview","color":"secondary","hide-details":""},model:{value:(_vm.previewRow),callback:function ($$v) {_vm.previewRow=$$v},expression:"previewRow"}})],1)],1)],1),_c('v-card-text',{staticClass:"pa-3 mb-6 overflow-auto"},[_c('cover-sheet-row',{key:_vm.selectedRowIndex + Math.random(),attrs:{"tab":_vm.tab,"selectedRowIndex":_vm.selectedRowIndex},on:{"goToRow":_vm.goToRow,"goToRows":_vm.goToRows}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }